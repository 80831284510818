import React from "react";
import "../css/about.scss";
import Image1 from "../assets/image-1.1.jpg";
import Image2 from "../assets/image-2.1.jpg";
import Image3 from "../assets/image-3.1.jpg";
import Image14 from "../assets/Palletech 14.jpg";
import Image11 from "../assets/Palletech 11.2.jpg";

function About() {
    return (
        <>
            <section id="about">
                <div id="about-div">
                    <div id="about-image">
                        <img id="image-1" src={Image1} alt="Pallets" />
                        <img id="image-3" src={Image3} alt="Pallets 2" />
                    </div>
                    <div id="about-paragraph">
                        <p>
                            <span>Palletech</span> is a locally owned and operated company that has been successfully serving our customers in the northern Nevada
                            area for over 12 years. We purchase used wood pallets and recycle and remanufacture the pallets for distribution.
                        </p>
                        <p>
                            <br />
                            <br />
                            <span>Palletech</span> is dedicated to providing our customers with excellent customer service, competitive pricing and a one-day
                            turnaround time on all orders.
                        </p>
                        <p>
                            <br />
                            <br />
                            When you choose <span>Palletech</span>, you are choosing a long-standing, professional pallet supplier, who appreciates and
                            understands your commitment to operating your business in an efficient and timely manner!
                        </p>
                        <p>
                            <br />
                            <br />
                            Call us today for more information.
                            <br />
                            <br />
                        </p>
                    </div>
                    <img id="image-2" src={Image2} alt="Pallets 3" />
                </div>
            </section>
            <section id="about2">
                <div id="about2-div">
                    <h3>Why Choose Us?</h3>
                    <ul>
                        <li>Efficient, one-day turn around time</li>
                        <li>Large fleet, giving us the ability to leave trailers on site</li>
                        <li>Competitive Pricing</li>
                        <li>Free pick-up and delivery within 100 miles of our plant</li>
                        <li>Palletech owns and operates our own equipment, and employs only licensed and experienced Class A drivers</li>
                        <li>Excellent, personal customer service</li>
                    </ul>
                </div>
                <div id="about2-div2">
                    <img src={Image14} alt="Pallets 14" />
                </div>
            </section>
            <section id="services">
                <div id="services-div2">
                    <img src={Image11} alt="Pallets 11" />
                </div>
                <div id="services-div">
                    <h3>What We Do</h3>
                    <ul>
                        <li>We provide standard 48x40 four-way pallets. (Recycled #2's)</li>
                        <li>We will buy and pick up your old pallets</li>
                        <li>Free delivery within 100 miles. (Longer delivers can be arranged with advance notice</li>
                        <li>Deliveries are made on either flatbed trailers or 48' vans, depending on your needs</li>
                    </ul>
                </div>
            </section>
        </>
    );
}

export default About;
