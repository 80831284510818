import React from "react";
import Nav from "./components/Nav";
import Main from "./components/Main";
import About from "./components/About";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import "./css/style.scss";

function App() {
    return (
        <div className="App">
            <Nav>
                <li>
                    <a href="#home" className="home_button">
                        Home
                    </a>
                </li>
                <li>
                    <a href="#about2" className="about_button">
                        About
                    </a>
                </li>
                <li>
                    <a href="#services" className="services_button">
                        Services
                    </a>
                </li>
                <li>
                    <a href="#contact" className="contact_button">
                        Contact
                    </a>
                </li>
            </Nav>
            <Main />
            <About />
            <Contact />
            <Footer />
        </div>
    );
}

export default App;
