import React, { useEffect } from "react";
import "../css/nav.scss";
import LogoMed from "../assets/logo-med.png";

interface Props {
    children: JSX.Element[] | JSX.Element;
}
function Nav(props: Props) {
    function navSlide() {
        var burger = document.querySelector(".burger") as HTMLElement;
        burger.addEventListener("click", () => {
            toggleNav("toggle");
        });
    }

    function toggleNav(open: string) {
        var nav = document.querySelector(".nav-links") as HTMLElement;
        var navLinks = document.querySelectorAll<HTMLElement>(".nav-links li");
        var clickOut = document.querySelector("#clickOut") as HTMLElement;
        var burger = document.querySelector(".burger") as HTMLElement;
        //Opens, closes or toggles nav
        if (open === "open") {
            nav.classList.add("nav-active");
        } else if (open === "close") {
            nav.classList.remove("nav-active");
        } else if (open === "toggle") {
            nav.classList.toggle("nav-active");
        }
        if (!navLinks[0].style.animation) {
            clickOut.style.display = "inline-block";
        } else {
            clickOut.style.display = "none";
        }
        navLinks.forEach((links, i) => {
            if (links.style.animation) {
                links.style.animation = "";
            } else {
                links.style.animation = `navLinkFade 0.3s ease forwards ${i / 7 + 0.3}s`;
            }
        });
        burger.classList.toggle("toggle");
    }

    function resize() {
        var navLinks = document.querySelector(".nav-links") as HTMLElement;
        if (window.innerHeight <= 768) {
            navLinks.style.transition = "transform 0.3s ease-in";
        } else {
            navLinks.style.transition = "";
        }
        checkTopLogo();
    }

    function checkTopLogo() {
        //Checks if user has scrolled past main logo. If true, displays logo on header
        var home = document.getElementById("home") as HTMLElement;
        var topLogo = document.querySelector(".logo4") as HTMLElement;
        if (window.scrollY + 200 > home.offsetHeight + home.offsetTop && window.innerWidth >= 769) {
            topLogo.classList.add("logo4-active");
        } else if (window.scrollY + 200 < home.offsetHeight + home.offsetTop && window.innerWidth > 768) {
            topLogo.classList.remove("logo4-active");
        } else if (window.innerWidth <= 768) {
            topLogo.classList.add("logo4-active");
        }
    }

    useEffect(() => {
        window.addEventListener("resize", resize);
        window.addEventListener("scroll", checkTopLogo);
        navSlide();
        checkTopLogo();
    });

    return (
        <>
            <div
                id="clickOut"
                onClick={() => {
                    toggleNav(`close`);
                }}></div>
            <nav>
                <img src={LogoMed} alt="logo" className="logo4" />
                <ul className="nav-links">{props.children}</ul>
                <div className="burger">
                    <div className="line1"></div>
                    <div className="line2"></div>
                    <div className="line3"></div>
                </div>
            </nav>
        </>
    );
}

export default Nav;
