import React from "react";
import smallLogo from "../assets/logo-small.png";
import "../css/footer.scss";

function Footer() {
    return (
        <>
            <hr />
            <footer>
                <div>
                    <div id="copyright">
                        <p>All Right Reserved © {new Date().getUTCFullYear()} Palletech</p>
                    </div>
                    <div id="footer-img">
                        <img src={smallLogo} alt="logo" />
                    </div>
                    <div id="footer-list">
                        <ul>
                            <li>(775) 440-7665</li>
                            <li>contact@palletech.com</li>
                            <li>1285 Spice Islands Dr</li>
                            <li>Sparks, NV 89431</li>
                        </ul>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;
