import React from "react";
import "../css/contact.scss";
import Portrait from "../assets/portrait.jpg";
import axios from "axios";
import qs from "qs";

function Contact() {
    function submit() {
        var fnameNode = document.getElementById("fname") as HTMLInputElement;
        var emailNode = document.getElementById("lname") as HTMLInputElement;
        var messageNode = document.getElementById("subject") as HTMLInputElement;

        type Response = { data: Object };
        axios({
            method: "post",
            url: "https://palletech.net/contact.php",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data: qs.stringify({
                fname: fnameNode.value,
                email: emailNode.value,
                message: messageNode.value,
            }),
        })
            .then(function (response: Response) {
                console.log(JSON.stringify(response.data));
                var statusMessage = document.getElementById("statusMessage") as HTMLElement;
                if (response.data === "Success") {
                    statusMessage.innerHTML = "Success. Thank you for contacting us";
                } else {
                    statusMessage.innerHTML = "Failed.";
                }
            })
            .catch(function (error: String) {
                console.log(error);
            });
    }

    return (
        <section id="contact">
            <div id="contact-head">
                <h1 className="main-text">Contact</h1>
            </div>
            <div id="contact-form">
                <div id="contact-right">
                    <p id="statusMessage" style={{ fontSize: "22px", color: "#e4e4a1" }}></p>
                    <form>
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="name">Name</label>
                            </div>
                            <div className="col-75">
                                <input type="text" id="fname" name="name" placeholder="Your name" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="email">Email</label>
                            </div>
                            <div className="col-75">
                                <input type="text" id="lname" name="email" placeholder="Your email" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-25">
                                <label htmlFor="subject">Message</label>
                            </div>
                            <div className="col-75">
                                <textarea id="subject" name="subject" placeholder="Write something.." style={{ height: "200px" }}></textarea>
                            </div>
                        </div>
                        <div className="row">
                            <input type="button" value="Submit" onClick={submit} />
                        </div>
                    </form>
                </div>
                <div id="contact-left">
                    <div id="contact-left-left">
                        <img src={Portrait} alt="Owner Jeff Cercek" />
                    </div>
                    <div id="contact-left-right">
                        <p>
                            <strong>Jeff Cercek</strong>
                        </p>
                        <p>
                            <i>Owner</i>
                        </p>
                        <p>jeff@palletech.com</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Contact;
