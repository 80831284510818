import React from "react";
import "../css/main.scss";
import Logo from "../assets/logo-med.png";
import LogoTitle from "../assets/logo-title.png";

function Main() {
    return (
        <section id="home">
            <div className="main-bg">
                <img src={Logo} alt="logo" className="logo" />
                <img src={LogoTitle} alt="logo" className="logo3" />
            </div>
        </section>
    );
}

export default Main;
